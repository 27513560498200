import React, { useState } from 'react'
import styles from './Technologies.module.scss'
import Image from 'next/image'

const Technologies = () => {
  const [activeCategory, setActiveCategory] = useState('Frontend')
  const technologiesArray: any = [
    {
      title: 'Frontend',
      technologies: [
        {
          title: 'React',
          image: '/technologies/react-hooks.png',
        },
        {
          title: 'X-State',
          image: '/technologies/x-state.svg',
        },
        {
          title: 'Redux',
          image: '/technologies/redux.svg',
        },
        {
          title: 'Mobx',
          image: '/technologies/mobx.jpeg',
        },
        {
          title: 'Zustand',
          image: '/technologies/zustand.png',
        },
      ],
    },

    {
      title: 'notifications',
      technologies: [
        {
          title: 'Firebase FCM',
          image: '/omnichannels/firebase.png',
        },
        {
          title: 'OneSignal',
          image: '/technologies/one-signal.png',
        },
        {
          title: 'Expo Push Notifications',
          image: '/technologies/expo-notifications.png',
        },
        {
          title: 'Amazon Simple Notification Service (SNS)',
          image: '/technologies/Amazon Simple Notification.webp',
        },
        {
          title: 'Catapush',
          image: '/technologies/catapush.webp',
        },
      ],
    },

    {
      title: 'ui-libraries',
      technologies: [
        {
          title: 'NativeBase',
          image: '/technologies/nativebase.webp',
        },
        {
          title: 'React Native Element',
          image: '/technologies/react-native-element.webp',
        },
        {
          title: 'React Native Paper',
          image: '/technologies/react-native-paper.webp',
        },
        {
          title: 'React Native UI Kitten',
          image: '/technologies/React Native UI Kitten.webp',
        },
        {
          title: 'RNUI',
          image: '/technologies/RNUI.webp',
        },
      ],
    },

    {
      title: 'development-tools',
      technologies: [
        {
          title: 'TypeScript',
          image: '/technologies/typescript.webp',
        },
        {
          title: 'Eslint',
          image: '/technologies/eslint.webp',
        },
        {
          title: 'Prettier',
          image: '/technologies/prettier.webp',
        },
        {
          title: 'CocoaPods',
          image: '/technologies/cocoapods.webp',
        },
        {
          title: 'xCode',
          image: '/technologies/x-code.webp',
        },
        {
          title: 'Java Development Kit & Android Studio',
          image: '/technologies/Java Development Kit & Android Studio.webp',
        },
      ],
    },

    {
      title: 'analytics',
      technologies: [
        {
          title: 'Mixpanel',
          image: '/technologies/mixpanel.webp',
        },
        {
          title: 'Google Analytics',
          image: '/technologies/google-analytics.webp',
        },
        {
          title: 'CleverTap',
          image: '/technologies/clevertap.webp',
        },
        {
          title: 'Sentry',
          image: '/technologies/sentry.webp',
        },
        {
          title: 'BugSnag',
          image: '/technologies/bugsnag.webp',
        },
        {
          title: 'Firebase Crashlytics',
          image: '/technologies/Firebase Crashlytics.webp',
        },
        {
          title: 'Instabug',
          image: '/technologies/instabug.webp',
        },
      ],
    },

    {
      title: 'ci-cd',
      technologies: [
        {
          title: 'Bitrise',
          image: '/technologies/bitrise.webp',
        },
        {
          title: 'AppCenter',
          image: '/technologies/appcenter.webp',
        },
        {
          title: 'FastLane',
          image: '/technologies/fastlane.webp',
        },
        {
          title: 'Expo EAS Builder',
          image: '/technologies/expo-EAS-builder.webp',
        },
        {
          title: 'Codemagic',
          image: '/technologies/codemagic.webp',
        },
      ],
    },

    {
      title: 'devops',
      technologies: [
        {
          title: 'Travis CI',
          image: '/technologies/travisCI.webp',
        },
        {
          title: 'Jenkins',
          image: '/technologies/jenkins.webp',
        },
        {
          title: 'Circle CI',
          image: '/technologies/circleCI.webp',
        },
        {
          title: 'Bitbucket',
          image: '/technologies/bitbucket.webp',
        },
        {
          title: 'Github Actions',
          image: '/technologies/github-actions.webp',
        },
        {
          title: 'Gitlab CI',
          image: '/technologies/gitlabCI.webp',
        },
        {
          title: 'Azure Pipeline',
          image: '/technologies/azure-pipeline.webp',
        },
        {
          title: 'AWS CodeBuild',
          image: '/technologies/aws-codebuild.webp',
        },
        {
          title: 'AWS CodePipeline',
          image: '/technologies/aws-codepipeline.webp',
        },
        {
          title: 'AWS CodeDeploy',
          image: '/technologies/aws-codedeploy.webp',
        },
        {
          title: 'AWS',
          image: '/technologies/aws.webp',
        },
        {
          title: 'Azure',
          image: '/technologies/azure.webp',
        },
        {
          title: 'GCP',
          image: '/technologies/GCP.webp',
        },
        {
          title: 'DigitalOcean',
          image: '/technologies/digital-ocean.webp',
        },
        {
          title: 'Promethus',
          image: '/technologies/promethus.webp',
        },
        {
          title: 'Zabbix',
          image: '/technologies/zabbix.webp',
        },
        {
          title: 'AWS Cloudewatch',
          image: '/technologies/aws-cloudewatch.webp',
        },
        {
          title: 'Azure Monitor',
          image: '/technologies/azure-monitor.webp',
        },
        {
          title: 'Dockers',
          image: '/technologies/dockers.webp',
        },
        {
          title: 'Kubernetes',
          image: '/technologies/kubernetes.webp',
        },
        {
          title: 'Ubuntu',
          image: '/technologies/ubuntu.webp',
        },
        {
          title: 'Amazone Linux',
          image: '/technologies/amazone-linux.webp',
        },
        {
          title: 'RHEL',
          image: '/technologies/RHEL.webp',
        },
        {
          title: 'AlmaLinux',
          image: '/technologies/almalinux.webp',
        },
        {
          title: 'RockyLinux',
          image: '/technologies/rockylinux.webp',
        },
        {
          title: 'Fedora',
          image: '/technologies/fedora.webp',
        },
        {
          title: 'Debian',
          image: '/technologies/debian.webp',
        },
        {
          title: 'Nagios',
          image: '/technologies/nagios.webp',
        },
        {
          title: 'Windows',
          image: '/technologies/windows.webp',
        },
        {
          title: 'MAC',
          image: '/technologies/MAC.webp',
        },
      ],
    },
  ]

  return (
    <div className={styles['technologies-wrapper']}>
      <div className={styles['technologies-container']}>
        <div className={styles['technologies-header']}>
          <p className={styles['technologies-heading']}>Technologies</p>
          <p className={styles['technologies-subheading']}>
            We work with cutting-edge technologies designed to solve your
            product requirements. Focus on finding solutions for your business
            needs while we handle the technical aspect.
          </p>
        </div>
        <div className={styles['technologies-list']}>
          <div className={styles['technology']}>
            <div className={`${styles['technology-image-container']}`}>
              {/* <Image src={'/react-native.webp'} alt='' width={72} height={63} className={styles['technology-image']}></Image> */}
              <Image
                src={'/react-native.webp'}
                alt=''
                width={60}
                height={50}
                className={styles['technology-image']}
              ></Image>
            </div>
            <p className={styles['technology-name']}>React Native</p>
          </div>
          <div className={styles['technology']}>
            <div className={`${styles['technology-image-container']}`}>
              {/* <Image src={'/nextjs.webp'} alt='' width={72} height={45} className={styles['technology-image']}></Image> */}
              <Image
                src={'/nextjs.webp'}
                alt=''
                width={65}
                height={45}
                className={styles['technology-image']}
              ></Image>
            </div>
            <p className={styles['technology-name']}>Next.js</p>
          </div>
          <div className={styles['technology']}>
            <div className={`${styles['technology-image-container']}`}>
              {/* <Image src={'/flutter.png'} alt='' width={72} height={89} className={styles['technology-image']}></Image> */}
              <Image
                src={'/flutter.png'}
                alt=''
                width={65}
                height={56}
                className={styles['technology-image']}
              ></Image>
            </div>
            <p className={styles['technology-name']}>Flutter</p>
          </div>
          <div className={styles['technology']}>
            <div className={`${styles['technology-image-container']}`}>
              {/* <Image src={'/graphql.png'} alt='' width={72} height={71} className={styles['technology-image']}></Image> */}
              <Image
                src={'/angular.png'}
                alt=''
                width={70}
                height={65}
                className={styles['technology-image']}
              ></Image>
            </div>
            <p className={styles['technology-name']}>Angular</p>
          </div>
          <div className={styles['technology']}>
            <div className={`${styles['technology-image-container']}`}>
              {/* <Image src={'/nodejs.png'} alt='' width={72} height={80} className={styles['technology-image']}></Image> */}
              <Image
                src={'/nodejs.png'}
                alt=''
                width={65}
                height={55}
                className={styles['technology-image']}
              ></Image>
            </div>
            <p className={styles['technology-name']}>Node.js</p>
          </div>
          <div className={styles['technology']}>
            <div className={`${styles['technology-image-container']}`}>
              {/* <Image src={'/postgre.png'} alt='' width={72} height={74} className={styles['technology-image']}></Image> */}
              <Image
                src={'/javaScript.png'}
                alt=''
                width={65}
                height={53}
                className={styles['technology-image']}
              ></Image>
            </div>
            <p className={styles['technology-name']}>JavaScript</p>
          </div>
          <div className={styles['technology']}>
            <div className={`${styles['technology-image-container']}`}>
              <Image
                src={'/dotnet.png'}
                alt=''
                width={72}
                height={33}
                className={styles['technology-image']}
              ></Image>
            </div>
            <p className={styles['technology-name']}>DotNet</p>
          </div>
          <div className={styles['technology']}>
            <div className={`${styles['technology-image-container']}`}>
              {/* <Image src={'/devops.webp'} alt='' width={72} height={33} className={styles['technology-image']}></Image> */}
              <Image
                src={'/devops.webp'}
                alt=''
                width={72}
                height={33}
                className={styles['technology-image']}
              ></Image>
            </div>
            <p className={styles['technology-name']}>DevOps</p>
          </div>
        </div>

        <div
          className={styles['technologies-list-by-category']}
          onMouseLeave={() => setActiveCategory('Frontend')}
        >
          <div className={styles['technologies-list-by-category-left']}>
            <p
              className={` ${styles['technology-category']} ${
                activeCategory === 'Frontend' ? styles.special : ''
              } `}
              onMouseEnter={() => {
                setActiveCategory('Frontend')
              }}
            >
              Frontend Store / State Management
            </p>
            <p
              className={` ${styles['technology-category']} ${
                activeCategory === 'notifications' ? styles.special : ''
              } `}
              onMouseEnter={() => {
                setActiveCategory('notifications')
              }}
            >
              Notifications Services
            </p>
            <p
              className={` ${styles['technology-category']} ${
                activeCategory === 'ui-libraries' ? styles.special : ''
              } `}
              onMouseEnter={() => {
                setActiveCategory('ui-libraries')
              }}
            >
              UI Libraries
            </p>
            <p
              className={` ${styles['technology-category']} ${
                activeCategory === 'development-tools' ? styles.special : ''
              } `}
              onMouseEnter={() => {
                setActiveCategory('development-tools')
              }}
            >
              Build/Development tools
            </p>
            <p
              className={` ${styles['technology-category']} ${
                activeCategory === 'analytics' ? styles.special : ''
              } `}
              onMouseEnter={() => {
                setActiveCategory('analytics')
              }}
            >
              Analytics / Crash Reporting
            </p>
            <p
              className={` ${styles['technology-category']} ${
                activeCategory === 'ci-cd' ? styles.special : ''
              } `}
              onMouseEnter={() => {
                setActiveCategory('ci-cd')
              }}
            >
              CI / CD Deployment Tools
            </p>
            <p
              className={` ${styles['technology-category']} ${
                activeCategory === 'devops' ? styles.special : ''
              } `}
              onMouseEnter={() => {
                setActiveCategory('devops')
              }}
            >
              DevOps
            </p>
            {/* <p className={styles['technology-category']} onMouseEnter={()=> setActiveCategory('testing')}>Testing</p>
                <p className={styles['technology-category']} onMouseEnter={()=> setActiveCategory('other')}>Other commonly used libraries</p> */}
          </div>
          <div className={styles['technologies-list-category-right']}>
            <div className={styles['icons']}>
              {technologiesArray
                .find((category: any) => category.title === activeCategory)
                ?.technologies.map((technology: any) => (
                  <div
                    key={technology.title}
                    className={styles['technology-item']}
                    data-tooltip={technology.title}
                  >
                    <Image
                      src={technology.image}
                      alt={technology.title}
                      height={48}
                      width={48}
                      title={technology.title}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Technologies
