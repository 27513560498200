import Head from 'next/head'
import Banner from '../components/Home/Banner/Banner'
import Offer from '../components/Home/Offer/Offer'
import Association from '../components/Home/Association/Association'
import Component3 from '../components/Home/Component3/Component3'
import Stats from '../components/Home/Stats/Stats'
import Component7 from '../components/Home/Component7/Component7'
import Innovator from '../components/Home/Innovator/Innovator'
import Connectors from '../components/Home/Connectors/Connectors'
import FeatureList from '../components/Home/FeatureList/FeatureList'
import Tecgnologies from '../components/Home/Technologies/Technologies'
import GenerateSales from '../components/Product/Generate-sales/GenerateSales'
import styles from '../styles/Home.module.scss'

export default function Home() {
  const statsData = {
    title: 'Our numbers speak for us',
    subTitle: 'Scaling across 5 countries operating across 15+ verticals',
    numbers: [
      {
        count: 32,
        title: 'Gen AI Applications',
      },
      {
        count: 20,
        title: 'Retail & B2B Clients',
      },
      {
        count: 2,
        title: 'Business to Retail ',
      },
      {
        count: 3,
        title: 'CPG',
      },
      {
        count: 9,
        title: 'Companies Incubated',
      },
      {
        count: 5,
        title: 'Enterprise Association',
      },
      {
        count: '50+',
        title: 'Projects Completed',
      },
      {
        count: 9,
        title: 'Startup Associations',
      },
    ],
  }

  return (
    <>
      <Head>
        <title>Socommerz</title>
        <meta name='title' content='Socommerz' />
        <meta
          name='description'
          content='To Kick start or Accelerate Early and Growth stage Startups with Technology, Platform, Mentorship & Business Solutions.'
        />
        <meta
          name='keywords'
          content='socommerz, socommerz technologies private limited, kick start your startup, socommerz gurugram, socommerz technologies'
        />
        <meta name='robots' content='index,follow' />
        <link rel='canonical' href='https://www.socommerz.com/' />
        <link
          rel='alternate'
          href='https://www.socommerz.com/'
          hrefLang='en-in'
        />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://www.socommerz.com/' />
        <meta property='og:title' content='Socommerz' />
        <meta
          property='og:description'
          content='To Kick start or Accelerate Early and Growth stage Startups with Technology, Platform, Mentorship & Business Solutions.'
        />

        <meta property='twitter:url' content='https://www.socommerz.com/' />
        <meta property='twitter:title' content='Socommerz' />
        <meta
          property='twitter:description'
          content='To Kick start or Accelerate Early and Growth stage Startups with Technology, Platform, Mentorship & Business Solutions.'
        />

        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org/',
              '@type': 'Organization',
              name: 'Socommerz',
              url: 'https://www.socommerz.com/',
              description:
                'To Kick start or Accelerate Early and Growth stage Startups with Technology, Platform, Mentorship & Business Solutions.',
              alternateName: 'Socommerz Technologies Private Limited',
              contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'customer support',
                email: 'engineering@socommerz.com',
              },
              sameAs: [
                'https://www.linkedin.com/company/socommerz',
                'https://www.facebook.com/Socommerz',
              ],
            }),
          }}
        />
      </Head>
      <main className={styles['home-container']}>
        <Banner />
        <Innovator />
        <br />
        <br />
        <FeatureList />
        <Stats data={statsData} />
        <br />
        <br />
        <Component3 />
        <GenerateSales />
        <Connectors />
        <Offer />
        <Association />
        <Tecgnologies />
        <Component7 />
      </main>
    </>
  )
}
