import React from 'react'
import Button from '../../Common/Button/Button'
import Stack from '../../Common/Stack/Stack'
import styles from './Component3.module.scss'
import Link from 'next/link'
import Image from 'next/image'

const Component3 = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={'tag'}>
          <span> What’s new?</span>
        </div>
        <p className={styles['heading']}>Our Headless commerce solution</p>
        <p className={styles['supporting-text']}>for all you business needs</p>
        <Link href='product-stack'>
          <Button text='Check it out' classname='button-wrapper' />
        </Link>
      </div>
      <div className={styles['images-div']}>
        <Image
          width={244}
          height={498}
          src='/screen-mockup3.webp'
          alt='screen-mockup'
          className={styles['iphone-mockup']}
        />
        <Image
          width={816}
          height={616}
          src='/screen-mockup2.webp'
          alt='screen-mockup'
          className={styles['tablet-screen']}
        />
      </div>
      <div className={styles['stack-div']}>
        <div className={styles['stack']}>
          <Stack
            path='features-icon.webp'
            heading='Powered by AI'
            text='Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.'
          />
          {/* <Link href='#' className={styles['link']}>Learn more-&gt;</Link> */}
        </div>

        <div className={styles['stack']}>
          <Stack
            path='product-icon10.png'
            heading='We speak the universal language'
            text='An all-in-one customer service platform that helps you balance everything your customers need to be happy.'
          />
          {/* <Link href='#' className={styles['link']}>Learn more-&gt;</Link> */}
        </div>

        <div className={styles['stack']}>
          <Stack
            path='product-icon5.png'
            heading='Cater to all domains'
            text='Measure what matters with Untitled’s easy-to-use reports. You can filter, export, and drilldown on the data in a couple clicks.'
          />
          {/* <Link href='#' className={styles['link']}>Learn more-&gt;</Link> */}
        </div>
      </div>
    </div>
  )
}

export default Component3
