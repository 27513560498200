import React from 'react';
import styles from './Stack.module.scss';

const Stack = (props: any) => {
  return (
    <div className={styles['container']}>
      <div className={styles['wrapper']}>
        <img src={props.path} alt='icon' style={{width:48, height:48}}/>
        <p className={styles['heading']}>{props.heading}</p>
        <p className={styles['supporting-text']}>
          {props.text}
        </p>
      </div>
    </div>
  );
};

export default Stack;
