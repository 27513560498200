import React from 'react'
import Button from '../../Common/Button/Button'
import Navbar from '../../Common/Navbar/Navbar'
import styles from './Banner.module.scss'

const Banner = () => {
  const stackData = [
    'B2B',
    'Retail',
    'B2C',
    'CPG',
    'Open AI',
    'Social Commerce',
    'Supply Chain',
    'D2C',
    'RPA',
  ]

  return (
    <div className={styles['banner-wrapper']}>
      {/* <img src='banner.webp' alt='banner' className={styles['bg-img']} /> */}
      <div className={styles['heading-box']}>
        <div className={styles['banner-heading']}>
          <div className={styles['words']}>
            <span className={styles.sp}>
              <p className={styles['we-enable']}>We enable&#160;</p>
              <p className={styles.entrepreneurs} style={{ color: '#2665ff' }}>
                entrepreneurs
              </p>
            </span>
            <span className={styles.sp}>
              <p className={styles['we-enable']}>We ensure&#160;</p>
              <p className={styles.entrepreneurs} style={{ color: '#15D3B9' }}>
                growth
              </p>
            </span>
            <span className={styles.sp}>
              <p className={styles['we-enable']}>Enroute&#160;</p>
              <p className={styles.entrepreneurs} style={{ color: '#A129FF' }}>
                incubation
              </p>
            </span>

            <span className={styles.sp}>
              <p className={styles['we-enable']}>We enable&#160;</p>
              <p className={styles.entrepreneurs} style={{ color: '#2665ff' }}>
                entrepreneurs
              </p>
            </span>
          </div>

          <div className={styles['banner-text-div']}>
            We exist to give enterprises the power to create and grow powerful
            businesses and make a meaningful impact.
          </div>

          <div className={styles['stack-list']}>
            {stackData.map((item: any, index: any) => (
              <div key={`stack-${index}`} className={`${styles['stack-tag']}`}>
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles['rocket-div']}>
        <img src='rocket.png' alt='rocket' />
      </div>
    </div>
  )
}

export default Banner
